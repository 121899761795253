import React, { useState } from 'react';
import { TextField, Button, Snackbar, Box } from '@mui/material';

export default function Contact({ handleClose }) {
    const [formData, setFormData] = useState({
        from: '',
        subject: '',
        content: ''
    });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [sending, setSending] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setSending(true);
            const response = await fetch('https://contact-form-6415-8f3r1n.twil.io/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                setSnackbarMessage('Email sent successfully');
            } else {
                setSnackbarMessage('Failed to send email');
            }

            setOpenSnackbar(true);
            setSending(false);
        } catch (error) {
            console.error('Error sending email:', error);
            setSnackbarMessage('An error occurred');
            setOpenSnackbar(true);
            setSending(false);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
        handleClose();
    };

    return (
        <div style={{ maxWidth: 600, maxHeight: 400, margin: 'auto', padding: 0 }}>
            <h1 style={{ textAlign: 'center' }}>Contact Us</h1>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    label="Email"
                    name="from"
                    value={formData.from}
                    onChange={handleChange}
                    required
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Message"
                    name="content"
                    multiline
                    rows={4}
                    value={formData.content}
                    onChange={handleChange}
                    required
                    margin="normal"
                />
                <Box textAlign='right'>
                    <Button type="submit" variant="contained" color="primary" style={{ marginTop: 20 }} disabled={sending}>
                        Send Email
                    </Button>
                </Box>
            </form>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />
        </div>
    );
}