import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
    {
        icon: <SettingsSuggestRoundedIcon />,
        title: 'Adaptable Design',
        description:
            'De-Gels has been designed in a way that can fit into any genre, allowing them to be part of any game.',
    },
    {
        icon: <ThumbUpAltRoundedIcon />,
        title: 'Utility',
        description:
            'De-Gels is designed in a way that all NFTs have foundational utility through our innovative "proof of profits" distribution model.',
    },
    {
        icon: <AutoFixHighRoundedIcon />,
        title: 'Innovative Distribution',
        description:
            'The De-Gels "proof of profits" distribution model is designed to be bear market resistant and provide long lasting rewards for those who participate.',
    },
    {
        icon: <ConstructionRoundedIcon />,
        title: 'Built to last',
        description:
            'De-Gels economic incentives and partnerships form the basis for a product built to last.',
    },
    {
        icon: <SupportAgentRoundedIcon />,
        title: 'Focus on Growth',
        description:
            'The De-Gels ecosystem\'s main goal is to provide users with a growing library of games and content to use with their De-Gel and $GEL tokens.',
    },
    {
        icon: <QueryStatsRoundedIcon />,
        title: 'Precision in every detail',
        description:
            'Enjoy a meticulously crafted product where small touches make a significant impact on your overall experience.',
    },
];

export default function Highlights() {
    return (
        <Box
            id="highlights"
            sx={{
                py: { xs: 2, sm: 4 },
                color: 'white',
                bgcolor: '#06090a',
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 2, sm: 4 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: 'center',
                    }}
                >
                    <Typography component="h2" variant="h4">
                        Highlights
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'grey.400' }}>
                        Explore why De-Gels stands out as the premier gaming NFT ecosystem: adaptability, utility,
                        innovation, durability, growth and precision in every detail. 
                    </Typography>
                </Box>
                <Grid container spacing={2.5}>
                    {items.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Stack
                                direction="column"
                                color="inherit"
                                component={Card}
                                spacing={1}
                                useFlexGap
                                sx={{
                                    p: 3,
                                    height: '100%',
                                    border: '1px solid',
                                    borderColor: 'grey.800',
                                    background: 'transparent',
                                    backgroundColor: 'grey.900',
                                }}
                            >
                                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                                <div>
                                    <Typography fontWeight="medium" gutterBottom>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'grey.400' }}>
                                        {item.description}
                                    </Typography>
                                </div>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}
