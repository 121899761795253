import React from 'react';
import { Popover, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';

export default function CustomPopup({ open, handleClose, content, w, h }) {
    return (
        <Popover
            open={open}
            onClose={handleClose}
            anchorReference="none"
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            PaperProps={{
                sx:{
                    width: w,
                    height: h, // Set height relative to viewport height
                },
                style: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', // Center horizontally and vertically
                    padding: '8px',
                    overflow: 'auto',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                },
            }}
        >
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex:99
                }}
            >
                <CloseIcon />
            </IconButton>
            <Box pb={1}/>
            <Box px={{xs:0, sm:2}}>
                {content}
            </Box>
        </Popover>
    );
}
