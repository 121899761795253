import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { PieChart } from '@mui/x-charts/PieChart';
import { cheerfulFiestaPalette } from '@mui/x-charts/colorPalettes';
import { LineChart } from '@mui/x-charts/LineChart';
import { Button } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';

const tokenomicsInfos = [
    {
        title: 'Token Distribution',
        subtitle: '',
        main: <PieChart
            colors={cheerfulFiestaPalette} // Use palette
            series={[{
                data: [
                    { label: 'Rewards', value: 60 },
                    { label: 'Private Sale', value: 15 },
                    { label: 'Public Sale', value: 10 },
                    { label: 'Team', value: 10 },
                    { label: 'Airdrops', value: 5 },
                ],
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -5, color: 'gray' },
                innerRadius: 20,
                outerRadius: 90,
                paddingAngle: 3,
                cornerRadius: 3,
                startAngle: 150,
                endAngle: 520,
                cy: 90,
                valueFormatter: (element) => `${element.value}%`,
            }]}
            height={275}
            margin={{ right: 0, top: 10 }}
            slotProps={{
                legend: {
                    direction: 'row',
                    position: { vertical: 'bottom', horizontal: 'middle' },
                    padding: -4,
                },
            }}
        />,
        description: '',
    },
    {
        title: 'Funding Allocation',
        subtitle: '',
        main: <PieChart
            colors={cheerfulFiestaPalette} // Use palette
            series={[{
                data: [
                    { label: 'Grants', value: 50 },
                    { label: 'Liquidity', value: 25 },
                    { label: 'Development', value: 10 },
                    { label: 'Marketing', value: 10 },
                    { label: 'Legal', value: 5 },
                ],
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -5, color: 'gray' },
                innerRadius: 20,
                outerRadius: 90,
                paddingAngle: 3,
                cornerRadius: 3,
                startAngle: 185,
                endAngle: 555,
                cy: 90,
                valueFormatter: (element) => `${element.value}%`,
            }]}
            height={275}
            margin={{ right: 0, top: 10 }}
            slotProps={{
                legend: {
                    direction: 'row',
                    position: { vertical: 'bottom', horizontal: 'middle' },
                    padding: -4,
                },
            }}
        />,
        description: '',
    },
    {
        title: 'Private Sale and Team Lock',
        subtitle: '',
        main:
            <LineChart
                colors={cheerfulFiestaPalette} // Use palette

                xAxis={[{ data: [0, 3, 6, 9, 12, 15, 18, 21, 24], scaleType: 'point' }]}
                yAxis={[{
                    valueFormatter: (element) => `${element}%`,
                }]}
                series={[
                    {
                        data: [20, 25, 30, 40, 50, 60, 70, 85, 100],
                        valueFormatter: (element) => `${element}%`,
                        area: true,
                        showMark: false,
                    },
                ]}
                height={250}
                margin={{ top: 10 }}
            />,
        description: '24 month unlocking schedule for team and private sales',
    },
];

export default function Tokenomics() {
    return (
        <Container
            id="tokenomics"
            sx={{
                py: { xs: 2, sm: 4 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '60%' },
                    textAlign: 'center',
                }}
            >
                <Typography component="h2" variant="h4" color="text.primary">
                    Tokenomics
                </Typography>

            </Box>
            <Grid container spacing={2}>
                {tokenomicsInfos.map((tokenomicInfo, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                flexGrow: 1,
                                p: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                }}
                            >
                                <CardHeader
                                    title={tokenomicInfo.title}
                                    subheader={tokenomicInfo.subtitle}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                }}
                            >
                                {tokenomicInfo.main}
                            </Box>
                            <CardContent sx={{ padding: 0, textAlign: 'center' }}>
                                <Typography variant="body2" color="text.secondary">
                                    {tokenomicInfo.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box textAlign={'center'}>
                <Typography gutterBottom pb={2}>
                    Want more info? Checkout out our whitepaper.
                </Typography>
                <Button endIcon={<OpenInNew/>} sx={{mb:2}} variant="contained" color="primary" size='large' href="https://de-gels.notion.site/Whitepaper-9539854972864fc0b9de422988ae12f0" target='_blank'>
                    Whitepaper
                </Button>
            </Box>
        </Container>
    );
}
