import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Highlights from './components/Highlights';
import Roadmap from './components/Roadmap';
import Features from './components/Features';
import Tokenomics from './components/Tokenomics';
import FAQ from './components/FAQ';
import Footer from './components/Footer';


export default function LandingPage() {
    const [mode, setMode] = React.useState('dark');
    //const [showCustomTheme, setShowCustomTheme] = React.useState(true);
    //const LPtheme = createTheme(getLPTheme(mode));
    const lightTheme = createTheme({
        palette: {
            mode: 'light',
            text: { icon: 'currentColor' },
            background: { surface: 'white' },
            primary: {
                main: "#208bb0"
            },
            secondary: {
                main: "#dbdbdb"
            }
        },
    });

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            text: { icon: 'currentColor' },
            background: { surface: 'black' },
            primary: {
                main: "#61D7FF"
            },
            secondary: {
                main: "#2a2a2a"
            }
        },
    });
    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
    };

    return (
        <ThemeProvider theme={mode === 'dark' ? darkTheme : lightTheme}>
            <CssBaseline />
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
            <Hero />
            <Box sx={{ bgcolor: 'background.default' }}>
                {/*<LogoCollection />*/}
                <Features />
                <Divider />
                <Tokenomics />
                <Divider />
                <Highlights />
                <Divider />
                <Roadmap />
                <Divider />
                <FAQ />
                <Divider />
                <Footer />
            </Box>
        </ThemeProvider>
    );
}
