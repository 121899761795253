import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Hero() {
    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #dbdbdb, #FFF)'
                        : `linear-gradient(#2a2a2a, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 10%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 12, sm: 18 },
                    pb: { xs: 4, sm: 6 },
                }}
            >
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                    <Typography
                        component="h1"
                        variant="h3"
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignSelf: 'center',
                            textAlign: 'center',
                        }}
                    >
                        Proof of Profits&nbsp;
                        <Typography
                            component="span"
                            variant="h3"
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                            }}
                        >
                            NFT
                        </Typography>
                    </Typography>
                    <Typography variant="body1" textAlign="center" color="text.secondary">
                        The very first proof of profits distribution model in an all new gaming ecosystem designed around an NFT collection of morphing gelatinous blob creatures called&nbsp;
                        <Typography
                            component="span"
                            variant="body1"
                            sx={{
                                color: (theme) =>
                                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                            }}
                        >
                            De-Gels.
                        </Typography>
                    </Typography>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignSelf="center"
                        spacing={1}
                        useFlexGap
                        sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
                    >
                        <Button variant="contained" color="primary" size='large' disabled href="https://app.de-gels.com" target='_blank'>
                            Launch App
                            <Box 
                            position={'absolute'}
                            sx={{
                                alignSelf: 'center',
                                height: '100%',
                                width: '100%',
                                backgroundImage:'url("/assets/comingSoon.png")',
                                backgroundSize: 'contain',
                                backgroundPosition: 'top left',
                                backgroundRepeat: 'no-repeat',
                            }}
                            />
                        </Button>
                    </Stack>
                </Stack>
                <Box
                    id="image"
                    sx={(theme) => ({
                        mt: { xs: 4, sm: 5 },
                        alignSelf: 'center',
                        height: { xs: 300, sm: 500 },
                        width: '100%',
                        backgroundImage:
                            theme.palette.mode === 'light'
                                ? 'url("/assets/degel-concept-art.png")'
                                : 'url("/assets/degel-concept-art-dark.png")',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: theme.palette.mode === 'light'
                        ? '#dadada' 
                        :'#4a4a4a',
                        borderRadius: '10px',
                        outline: '1px solid',
                        outlineColor:
                            theme.palette.mode === 'light'
                                ? alpha('#BFCCD9', 0.5)
                                : alpha('#9CCCFC', 0.1),
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 12px 8px ${alpha('#208bb0', 0.2)}`
                                : `0 0 24px 12px ${alpha('#61D7FF', 0.2)}`,
                    })}
                />
            </Container>
        </Box>
    );
}
