import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/X';
import logo from '../logo.gif';
import { useState } from 'react';
import CustomPopup from './CustomPopup';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';
import Contact from './Contact';

import { useTheme } from '@mui/system';
import { OpenInNew } from '@mui/icons-material';

const logoStyle = {
    width: '200px',
    height: 'auto',
};

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" mt={1}>
            {'Copyright © '}
            <Link href="https://de-gels.com/">De-Gels</Link>
            &nbsp;
            {new Date().getFullYear()}
        </Typography>
    );
}

export default function Footer() {
    const [termsPopupOpen, setTermsPopupOpen] = useState(false);
    const [privacyPopupOpen, setPrivacyPopupOpen] = useState(false);
    const [contactPopupOpen, setContactPopupOpen] = useState(false);
    const [subscribePopupOpen, setSubscribePopupOpen] = useState(false);

    const theme = useTheme();

    const handleTermsPopupOpen = () => {
        setTermsPopupOpen(true);
    };

    const handleTermsPopupClose = () => {
        setTermsPopupOpen(false);
    };

    const handlePrivacyPopupOpen = () => {
        setPrivacyPopupOpen(true);
    };

    const handlePrivacyPopupClose = () => {
        setPrivacyPopupOpen(false);
    };

    const handleContactPopupOpen = () => {
        setContactPopupOpen(true);
    };
    const handleContactPopupClose = () => {
        setContactPopupOpen(false);
    };

    const handleSubscribePopupOpen = () => {
        setSubscribePopupOpen(true);
    };
    const handleSubscribePopupClose = () => {
        setSubscribePopupOpen(false);
    };
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 0,
                py: 4,
                textAlign: { sm: 'center', md: 'left' },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        minWidth: { xs: '100%', sm: '60%' },
                    }}
                >
                    <Box sx={{ width: { xs: '100%', sm: '370px' }, pb: 4 }}>
                        <Box sx={{ ml: '-15px' }}>
                            <img
                                src={
                                    logo
                                }
                                style={logoStyle}
                                alt="logo of sitemark"
                            />
                        </Box>
                        <Typography variant="body2" fontWeight={600} gutterBottom>
                            Subscribe
                        </Typography>
                        <Typography variant="body2" color="text.secondary" mb={2}>
                            Keep up to date and receive the latest news about the De-Gels ecosystem.
                        </Typography>
                        <Button variant="contained" color="primary" size='large' onClick={handleSubscribePopupOpen}>
                            Subscribe
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Product
                    </Typography>
                    <Link color="text.secondary" href="#features">
                        Features
                    </Link>
                    <Link color="text.secondary" href="#tokenomics">
                        Tokenomics
                    </Link>
                    <Link color="text.secondary" href="#highlights">
                        Highlights
                    </Link>
                    <Link color="text.secondary" href="#roadmap">
                        Roadmap
                    </Link>
                    <Link color="text.secondary" href="#faq">
                        FAQs
                    </Link>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Company
                    </Typography>
                    <Link color="text.secondary" target="_blank"
                        rel="noopener noreferrer"
                        href="https://de-gels.notion.site/Whitepaper-9539854972864fc0b9de422988ae12f0">
                        Whitepaper
                        <OpenInNew sx={{pt:1, mb:-0.5}}/>
                    </Link>
                    <Link color="text.secondary" onClick={handleContactPopupOpen} sx={{cursor:"pointer"}}>
                        Careers
                    </Link>
                    <Link color="text.secondary" onClick={handleContactPopupOpen} sx={{cursor:"pointer"}}>
                        Contact
                    </Link>
                    <Link color="text.secondary" onClick={handleContactPopupOpen} sx={{cursor:"pointer"}}>
                        Press
                    </Link>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', sm: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" fontWeight={600}>
                        Legal
                    </Typography>
                    <Link color="text.secondary" onClick={handleTermsPopupOpen} sx={{cursor:"pointer"}}>
                        Terms
                    </Link>
                    <Link color="text.secondary" onClick={handlePrivacyPopupOpen} sx={{cursor:"pointer"}}>
                        Privacy
                    </Link>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: { xs: 2, sm: 8 },
                    width: '100%',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <div>
                    <Link color="text.secondary" onClick={handlePrivacyPopupOpen} sx={{cursor:"pointer"}}>
                        Privacy Policy
                    </Link>
                    <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
                        &nbsp;•&nbsp;
                    </Typography>
                    <Link color="text.secondary" onClick={handleTermsPopupOpen} sx={{cursor:"pointer"}}>
                        Terms of Use
                    </Link>
                    <Copyright />
                </div>
                <Stack
                    direction="row"
                    justifyContent="left"
                    spacing={1}
                    useFlexGap
                    sx={{
                        color: 'text.secondary',
                    }}
                >
                    <IconButton
                        color="inherit"
                        href="https://github.com/Degels"
                        aria-label="GitHub"
                        sx={{ alignSelf: 'center' }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FacebookIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        href="https://twitter.com/De_Gels"
                        aria-label="X"
                        sx={{ alignSelf: 'center' }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <TwitterIcon />
                    </IconButton>
                </Stack>
            </Box>
            <CustomPopup
                open={termsPopupOpen}
                handleClose={handleTermsPopupClose}
                content={<TermsOfUse />}
                w={{ xs: '90vw', sm: '70vw' }}
                h={'75vh'}
            />

            <CustomPopup
                open={privacyPopupOpen}
                handleClose={handlePrivacyPopupClose}
                content={<PrivacyPolicy />}
                w={{ xs: '90vw', sm: '70vw' }}
                h={'75vh'}
            />
            <CustomPopup
                open={contactPopupOpen}
                handleClose={handleContactPopupClose}
                content={<Contact handleClose={handleContactPopupClose} />}
                w={{ xs: '90vw', sm: '55vw' }}
                h={'75vh'}
            />
            <CustomPopup
                open={subscribePopupOpen}
                handleClose={handleSubscribePopupClose}
                content={
                    theme.palette.mode === 'dark' ?
                        <Box sx={{ height: '64vh', position: 'relative' }}>
                            <h1 style={{ textAlign: 'center' }}>Subscribe</h1>
                            <iframe title='subscribe' style={{ position: 'absolute', top: '50%', msTransform: 'translateY(-40%)', transform: 'translateY(-40%)', width: '100%', height: '60vh', border: 0 }} src="https://cdn.forms-content-1.sg-form.com/c1f47179-f5f8-11ee-a893-b215c4dd4190" />
                        </Box>
                        :
                        <Box sx={{ height: '64vh', position: 'relative' }}>
                            <h1 style={{ textAlign: 'center' }}>Subscribe</h1>
                            <iframe title='subscribe' style={{ position: 'absolute', top: '50%', msTransform: 'translateY(-40%)', transform: 'translateY(-40%)', width: '100%', height: '60vh', border: 0 }} src="https://cdn.forms-content-1.sg-form.com/3e892e33-f5fd-11ee-a893-b215c4dd4190" />
                        </Box>
                }
                w={{ xs: '95vw', sm: '45vw' }}
                h={'75vh'}
            />
        </Container>
    );
}
