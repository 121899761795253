import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import BuildIcon from '@mui/icons-material/Build';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DrawIcon from '@mui/icons-material/Draw';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import HowToVoteIcon from '@mui/icons-material/HowToVote';

const items = [
    {
        icon: <BuildIcon />,
        title: 'Developer Tools',
        description:
            'Develop SDKs for various game engines to seamlessly integrate NFT ownership based usage of De-Gels as player characters, monsters, or pets using our game ready models.',
    },
    {
        icon: <CurrencyExchangeIcon />,
        title: 'Developer Grants',
        description:
            'Distribute developer grants based on proposals for games centered around the use of De-Gels and/or $GEL tokens',
    },
    {
        icon: <DrawIcon />,
        title: 'Morphs',
        description:
            'Accept applications for Morphs with custom 3D models that can be used in games. Morphs can be given as rewards, sold in-game, sold as NFTs, or distributed in any other way they would like.',
    },
    {
        icon: <HandshakeIcon />,
        title: 'Partnerships, Chains and Tokens',
        description:
            'The De-Gels team will actively pursue partnerships with blockchains and other crypto projects to increase accessibility and attract new users.',
    },
    {
        icon: <LocalGroceryStoreIcon />,
        title: 'Marketplace',
        description:
            'The creation of a physical and digital goods marketplace that includes De-Gels branded goods and custom Morphs that can be purchased for $GEL tokens.',
    },
    {
        icon: <HowToVoteIcon />,
        title: 'DAO',
        description:
            'De-Gels will transition to a Decentralized Autonomous Exchange (DAO) over time, which will allow all $GEL holders to elect leaders, make proposals and vote on them.',
    },
];

export default function Roadmap() {
    return (
        <Box
            id="roadmap"
            sx={{
                py: { xs: 2, sm: 4 },
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 2, sm: 4 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography component="h2" variant="h4">
                        Roadmap
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    {items.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Stack
                                direction="column"
                                color="inherit"
                                component={Card}
                                spacing={1}
                                useFlexGap
                                sx={{
                                    p: 3,
                                    height: '100%',
                                    border: '1px solid',
                                    borderColor: 'grey.800', 
                                }}
                            >
                                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                                <div>
                                    <Typography fontWeight="medium" gutterBottom>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2">
                                        {item.description}
                                    </Typography>
                                </div>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}
