import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import AutoGraph from '@mui/icons-material/AutoGraph';
import { AddBox, DisplaySettings, PlusOne, SportsEsports } from '@mui/icons-material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import PaidIcon from '@mui/icons-material/Paid';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import WalletIcon from '@mui/icons-material/Wallet';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import PaymentIcon from '@mui/icons-material/Payment';

const earnItems = [
    {
        icon: <AccountBalanceWalletIcon />,
        title: 'Deposit',
        description:
            'Give your De-Gel some crypto tokens that have approved chainlink price feeds such as ETH, WBTC or LINK.',
    },
    {
        icon: <MoreTimeIcon />,
        title: 'Wait',
        description:
            'Wait for the tokens to be more valuable than when you deposited them.',
    },
    {
        icon: <PaidIcon />,
        title: 'Earn',
        description:
            'Take back your tokens while they are in profit to earn $GEL tokens.',
    },
];
const levelUpItems = [
    {
        icon: <SaveAltIcon />,
        title: 'Collect',
        description:
            'Collect enough $GEL earnings for your De-Gel to reach the next level.',
    },
    {
        icon: <UpgradeIcon />,
        title: 'Upgrade',
        description:
            'Select the stat points to increase on your De-Gel to make it unique.',
    },
    {
        icon: <WalletIcon />,
        title: 'Extract',
        description:
            'Extract the $GEL from your De-Gel to progress to the next level',
    },
];

const morphItems = [
    {
        icon: <ShoppingCartIcon />,
        title: 'Purchase',
        description:
            'Buy De-Gel morphs from various approved vendors with unique designs to fit into different games.',
    },
    {
        icon: <AddBox />,
        title: 'Add',
        description:
            'Add the morph to your De-Gel so that it can become something new.',
    },
    {
        icon: <ChangeCircleIcon />,
        title: 'Change',
        description:
            'Switch between all morphs that have been added to your De-Gel at any time.',
    },
];

const gamesItems = [
    {
        icon: <DirectionsRunIcon />,
        title: 'Characters',
        description:
            'Play as your De-Gel in a growing library of supported games.',
    },
    {
        icon: <TwoWheelerIcon />,
        title: 'Equipment',
        description:
            'Use your morphed De-Gel as unique items in various games.',
    },
    {
        icon: <PaymentIcon />,
        title: 'Currency',
        description:
            'Use the $GEL you earn to make in-app purchases in various games.',
    },
];


const items = [
    {
        icon: <AutoGraph />,
        title: 'Earn $GEL',
        description:
            'Get your very own De-Gel NFT to start earning $GEL',
        imageLight: 'url("/assets/Features1Light.png")',
        imageDark: 'url("/assets/Features1Dark.png")',
        info: <Grid container spacing={2}>
            {earnItems.map((item, index) => (
                <Grid item xs={12} sm={12} md={12} key={index} textAlign={'center'} minHeight={'fit-content'}>
                    <Stack
                        direction="column"
                        color="inherit"
                        component={Card}
                        spacing={1}
                        useFlexGap
                        sx={{
                            p: 3,
                            height: '100%',
                            border: '1px solid',
                            borderColor: 'grey.800',       
                        }}
                    >
                        <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                        <div>
                            <Typography fontWeight="medium" gutterBottom>
                                {item.title}
                            </Typography>
                            <Typography variant="body2" >
                                {item.description}
                            </Typography>
                        </div>
                    </Stack>
                </Grid>
            ))}
        </Grid>
        //info: '{An animation of the owner giving the puddle De-Gel an ETH token which transitions it to baby De-Gel, the baby does a little dance as the token grows. The baby gives back the ETH token and changes into a teen De-Gel}'
    },
    {
        icon: <PlusOne />,
        title: 'Level Up',
        description:
            'Extract $GEL to level up your De-Gel to increase it\'s stat points and earnings.',
        imageLight: 'url("/assets/Features2Light.png")',
        imageDark: 'url("/assets/Features2Dark.png")',
        info: <Grid container spacing={2}>
            {levelUpItems.map((item, index) => (
                <Grid item xs={12} sm={12} md={12} key={index} textAlign={'center'} minHeight={'fit-content'}>
                    <Stack
                        direction="column"
                        color="inherit"
                        component={Card}
                        spacing={1}
                        useFlexGap
                        sx={{
                            p: 3,
                            height: '100%',
                            border: '1px solid',
                            borderColor: 'grey.800',      
                        }}
                    >
                        <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                        <div>
                            <Typography fontWeight="medium" gutterBottom>
                                {item.title}
                            </Typography>
                            <Typography variant="body2" >
                                {item.description}
                            </Typography>
                        </div>
                    </Stack>
                </Grid>
            ))}
        </Grid>
        //info: '{An animation of the adult De-Gel giving the owner some $GEL tokens, showing a +1 level up, then turning into a puddle}'
    },
    {
        icon: <DisplaySettings />,
        title: 'Customize',
        description:
            'Use $GEL to buy morphs from many vendors to change the look and stats of your De-Gel.',
        imageLight: 'url("/assets/Features3Light.png")',
        imageDark: 'url("/assets/Features3Dark.png")',
        info: <Grid container spacing={2}>
            {morphItems.map((item, index) => (
                <Grid item xs={12} sm={12} md={12} key={index} textAlign={'center'} minHeight={'fit-content'}>
                    <Stack
                        direction="column"
                        color="inherit"
                        component={Card}
                        spacing={1}
                        useFlexGap
                        sx={{
                            p: 3,
                            height: '100%',
                            border: '1px solid',
                            borderColor: 'grey.800',   
                        }}
                    >
                        <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                        <div>
                            <Typography fontWeight="medium" gutterBottom>
                                {item.title}
                            </Typography>
                            <Typography variant="body2" >
                                {item.description}
                            </Typography>
                        </div>
                    </Stack>
                </Grid>
            ))}
        </Grid>,
        //info: '{An animation of an adult De-Gel changing back and forth into various forms: gun, car, game character, etc...}',
        subtext: 'De-Gels gelatinous body is not just cool to look at, it can change into any shape it likes. Opening up an unlimited number of possibilities with Morphs that fit into any game.'
    },
    {
        icon: <SportsEsports />,
        title: 'Play Games',
        description:
            'Use your De-Gel and $GEL to play games!',
        imageLight: 'url("/assets/Features4Light.png")',
        imageDark: 'url("/assets/Features4Dark.png")',
        info: <Grid container spacing={2}>
            {gamesItems.map((item, index) => (
                <Grid item xs={12} sm={12} md={12} key={index} textAlign={'center'} minHeight={'fit-content'}>
                    <Stack
                        direction="column"
                        color="inherit"
                        component={Card}
                        spacing={1}
                        useFlexGap
                        sx={{
                            p: 3,
                            height: '100%',
                            border: '1px solid',
                            borderColor: 'grey.800', 
                        }}
                    >
                        <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                        <div>
                            <Typography fontWeight="medium" gutterBottom>
                                {item.title}
                            </Typography>
                            <Typography variant="body2" >
                                {item.description}
                            </Typography>
                        </div>
                    </Stack>
                </Grid>
            ))}
        </Grid>
        //info: '{An animation of a character playing a fornite-esque game and their De-Gel morphing into a gun to help them}'
    },
];

export default function Features() {
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

    const handleItemClick = (index) => {
        setSelectedItemIndex(index);
    };

    const selectedFeature = items[selectedItemIndex];

    return (
        <Container id="features" sx={{ py: { xs: 1, sm: 2 } }}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <div>
                        <Typography component="h2" variant="h4" color="text.primary">
                            What are De-Gels?
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{ mb: { xs: 1, sm: 2 } }}
                        >
                            De-Gel NFTs are both financial and gaming assets that are the foundation of the De-Gel gaming ecosystem. They are all equally rare, until you put in the work to turn them into De-Gel gods.
                        </Typography>

                    </div>
                    <Grid container item gap={1} pt={4} justifyContent={'center'} sx={{ display: { xs: 'auto', sm: 'none' } }}>
                        {items.map(({ title }, index) => (
                            <Chip
                                key={index}
                                label={title}
                                onClick={() => handleItemClick(index)}
                                sx={{
                                    borderColor: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index ? 'primary.light' : '';
                                        }
                                        return selectedItemIndex === index ? 'primary.light' : '';
                                    },
                                    background: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index ? 'none' : '';
                                        }
                                        return selectedItemIndex === index ? 'none' : '';
                                    },
                                    backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                                    '& .MuiChip-label': {
                                        color: selectedItemIndex === index ? '#fff' : '',
                                    },
                                }}
                            />
                        ))}
                    </Grid>
                    <Box
                        component={Card}
                        variant="outlined"
                        sx={{
                            display: { xs: 'auto', sm: 'none' },
                            mt: 4,
                        }}
                    >
                        <Box
                            sx={{
                                backgroundImage: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? items[selectedItemIndex].imageLight
                                        : items[selectedItemIndex].imageDark,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                minHeight: 280,
                                alignContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {items[selectedItemIndex].info}
                        </Box>
                        <Box sx={{ px: 2, pb: 2, pt:2 }}>
                            <Typography color="text.primary" variant="body2" fontWeight="bold">
                                {selectedFeature.title}
                            </Typography>
                            <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                                {selectedFeature.description}
                            </Typography>
                        </Box>
                    </Box>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={1}
                        useFlexGap
                        sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
                    >
                        {items.map(({ icon, title, description }, index) => (
                            <Card
                                key={index}
                                variant="outlined"
                                component={Button}
                                onClick={() => handleItemClick(index)}
                                sx={{
                                    p: 3,
                                    height: 'fit-content',
                                    width: '100%',
                                    background: 'none',
                                    backgroundColor:
                                        selectedItemIndex === index ? 'action.selected' : undefined,
                                    borderColor: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index
                                                ? 'primary.light'
                                                : 'grey.200';
                                        }
                                        return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        textAlign: 'left',
                                        flexDirection: { xs: 'column', md: 'row' },
                                        alignItems: { md: 'center' },
                                        gap: 2.5,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: (theme) => {
                                                if (theme.palette.mode === 'light') {
                                                    return selectedItemIndex === index
                                                        ? 'primary.main'
                                                        : 'grey.300';
                                                }
                                                return selectedItemIndex === index
                                                    ? 'primary.main'
                                                    : 'grey.700';
                                            },
                                        }}
                                    >
                                        {icon}
                                    </Box>
                                    <Box sx={{ textTransform: 'none' }}>
                                        <Typography
                                            color="text.primary"
                                            variant="body2"
                                            fontWeight="bold"
                                        >
                                            {title}
                                        </Typography>
                                        <Typography
                                            color="text.secondary"
                                            variant="body2"
                                            sx={{ my: 0.5 }}
                                        >
                                            {description}
                                        </Typography>
                                        <Link
                                            color="primary"
                                            variant="body2"
                                            fontWeight="bold"
                                            sx={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                '& > svg': { transition: '0.2s' },
                                                '&:hover > svg': { transform: 'translateX(2px)' },
                                            }}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                            }}
                                        >
                                            <span>Learn more</span>
                                            <ChevronRightRoundedIcon
                                                fontSize="small"
                                                sx={{ mt: '1px', ml: '2px' }}
                                            />
                                        </Link>
                                    </Box>
                                </Box>
                            </Card>
                        ))}
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: { xs: 'none', sm: 'flex' },
                            pointerEvents: 'none',
                        }}
                    >
                        <Box
                            sx={{
                                m: 'auto',
                                width: 420,
                                height: 500,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundImage: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? items[selectedItemIndex].imageLight
                                        : items[selectedItemIndex].imageDark,
                                alignContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {items[selectedItemIndex].info}
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
